import { debounce } from '../modules/debounce.js';

(() => {
  const sizeSaccordions = document.querySelectorAll('.-s-Accordion');
  if (sizeSaccordions.length === 0) return;

  function desktopify() {
    sizeSaccordions.forEach(accordion => {
      accordion.setAttribute('open', 'open');
    });
  }
  function mobileify() {
    sizeSaccordions.forEach(accordion => {
      accordion.removeAttribute('open');
    });
  }

  // window.resize callback function
  function recalculateAccordions() {
    if (window.innerWidth < 591) {
      mobileify();
    }
    else {
      desktopify();
    }
  }

  // window.resize event listener
  window.addEventListener('resize', debounce(recalculateAccordions));
  recalculateAccordions();
})();
