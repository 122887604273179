(() => {
  const trigger = document.querySelector('.js-nav-trigger');
  const nav = document.querySelector('.Nav');
  const offsetY = 0;

  if (nav === null || trigger === null) return;

  // Expand the mobile navigation
  function expand() {
    if (!nav.classList.contains('is-expanded')) {
      nav.classList.add('is-expanded');
      return;
    }

    nav.classList.remove('is-expanded');
  }

  function navScrollHandler() {
    if (window.scrollY > 0 + offsetY) nav.classList.add('is-fixed');
    else nav.classList.remove('is-fixed');
  }

  // Add active class to the element that links to the current page
  const navLink = nav.querySelector(`.Nav-content a[href="${window.location.pathname}"]:not(.Btn)`);
  if (navLink) navLink.classList.add('is-active');

  trigger.addEventListener('click', expand);
  window.addEventListener('scroll', navScrollHandler);
  window.addEventListener('load', navScrollHandler);
})();
